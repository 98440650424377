/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

    $.resizeend = function(el, options){
      var base = this;

      base.$el = $(el);
      base.el = el;

      base.$el.data("resizeend", base);
      base.rtime = new Date(1, 1, 2000, 12,0,0);
      base.timeout = false;
      base.delta = 200;

      base.init = function(){
          base.options = $.extend({},$.resizeend.defaultOptions, options);

          if(base.options.runOnStart) base.options.onDragEnd();

          $(base.el).resize(function() {

              base.rtime = new Date();
              if (base.timeout === false) {
                  base.timeout = true;
                  setTimeout(base.resizeend, base.delta);
              }
          });

      };
      base.resizeend = function() {
          if (new Date() - base.rtime < base.delta) {
              setTimeout(base.resizeend, base.delta);
          } else {
              base.timeout = false;
              base.options.onDragEnd();
          }
      };

      base.init();
  };

  $.resizeend.defaultOptions = {
      onDragEnd : function() {},
      runOnStart : false
  };

  $.fn.resizeend = function(options){
      return this.each(function(){
          (new $.resizeend(this, options));
      });
  };

  jQuery.fn.scrollOffset = function () {
      var win = $(window);
      var topSpace = this.offset().top - win.scrollTop();
      var bottomSpace = win.height() - this.height() - topSpace;
      var leftSpace = this.offset().left;
      var rightSpace = win.width() - this.width() - leftSpace;
      return { top: topSpace, bottom: bottomSpace, left: leftSpace, right: rightSpace };
  };

  function isElementInViewport (el, verticaloffset) {
    var win = $(window);

    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = el.offset();
    bounds.right = bounds.left + el.outerWidth();
    bounds.bottom = bounds.top + el.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

  }

  function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
  }


  function set_animation(el) {
  el.addClass('animated');
  if (el.hasClass('down')) {
    el.addClass('fadeInDown');
    el.removeClass('down');
  } else if (el.hasClass('left')) {
    el.addClass('fadeInLeft');
    el.removeClass('left');
  } else if (el.hasClass('right')) {
    el.addClass('fadeInRight');
    el.removeClass('right');
  } else if (el.hasClass('in')) {
    el.addClass('fadeIn');
    el.removeClass('in');
  } else if (el.hasClass('up')) {
    el.addClass('fadeInUp');
    el.removeClass('up');
  }
}

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Roots = {

  /*
    Preload
  */
  preload: {
    init: function() {


      // $('.modalOpen').on('click', function(e){
      //   e.preventDefault();
      //   $('.modal__containter').addClass('modal__open');
      //   setTimeout(function(){
      //      $('.modal').removeClass('hide');
      //      $('.modal').addClass('transition');
      //    });
      //    setTimeout(function(){
      //      $('.modal').removeClass('transition');
      //      $('.modal').addClass('visible');
      //      },50);
      //  });

      $('.modalOpen').on('click', function(e){
        e.preventDefault();
        var dataMember = $(this).attr('data-member');
       $('#' + dataMember).addClass('modal__open');
       setTimeout(function(){
         $('#' + dataMember).find('.modal').removeClass('hide');
         $('#' + dataMember).find('.modal').addClass('transition');
       });
       setTimeout(function(){
        $('#' + dataMember).find('.modal').removeClass('transition');
        $('#' + dataMember).find('.modal').addClass('visible');
         },50);
     });

       $('.modal__close').on('click', function(e){
         e.preventDefault();
         $('.modal__containter').removeClass('modal__open');
         $('.modal').removeClass('visible');
         $('.modal').addClass('transition');
         setTimeout(function(){
           $('.modal').removeClass('transition');
           $('.modal').addClass('hide');
         },500);
       });

       $('.modal__containter').on('click', function(e){
         e.preventDefault();
         $('.modal__containter').removeClass('modal__open');
         $('.modal').removeClass('visible');
         $('.modal').addClass('transition');
         setTimeout(function(){
           $('.modal').removeClass('transition');
           $('.modal').addClass('hide');
         },500);
       });
         $(document).keydown(function(e){
    if(e.keyCode == 27) {
      $('.modal__containter').removeClass('modal__open');
      $('.modal').removeClass('visible');
      $('.modal').addClass('transition');
      setTimeout(function(){
        $('.modal').removeClass('transition');
        $('.modal').addClass('hide');
      },500);
    }
  });


      $('.section__video .video__play').on('click', function(ev) {
          var video = $(this).parent().siblings('iframe');
          var overlay = $(this).parent();

          overlay.fadeOut();
          video[0].src += "&autoplay=1";
          ev.preventDefault();

        });

      /* Slider */
        var currentIndex_testimonial_slider = 0,
          items_testimonial_slider = $('.testimonial_slider > ul li'),
          toggle_testimonial_slider = $('.testimonial_slider__toggle.autoslide li'),
          itemAmt_testimonial_slider = items_testimonial_slider.length;



        autoSlide = setInterval(function() {
          items_testimonial_slider.removeClass('active');
          toggle_testimonial_slider.removeClass('active');
          currentIndex_testimonial_slider += 1;
          if (currentIndex_testimonial_slider > itemAmt_testimonial_slider - 1) {
            currentIndex_testimonial_slider = 0;
          }
          cycleItems();
        }, 6000);

        /* Toggles */
        $('.testimonial_slider__toggle li').on('click', function(event) {
          event.preventDefault();
          var slide = $(this).attr('data-slide');
          var slide_num = parseInt(slide);
          var leftPos = $(this).closest('.section').find('.testimonial_slider > ul').scrollLeft(),
            itemSize = $(this).closest('.section').find('.testimonial_slider li').outerWidth();
            ulSize = $(this).closest('.section').find('.testimonial_slider > ul').outerWidth();
            ulSize = parseInt(ulSize);

          /* Remove class active */
          $(this).closest('.section').find('.testimonial_slider__toggle li').removeClass('active');
          $(this).closest('.section').find('.testimonial_slider li').removeClass('active');
          $(this).closest('.section').find('.testimonial__wrapper').removeClass('active');

          /* Add class active to this toggle and the event that it is associated with */


          $(this).closest('.section').find('.testimonial_slider__toggle li[data-slide="'+slide+'"]').addClass('active');
          $(this).closest('.section').find('.testimonial_slider li[data-slide="'+slide+'"]').addClass('active');
          $(this).closest('.section').find('.testimonial__wrapper[data-slide="'+slide+'"]').addClass('active');

          if($(this).attr('data-slide') == itemAmt_testimonial_slider - 1) {
            $(this).closest('.section').find(".testimonial_slider > ul").animate({
              scrollLeft: 0 + (itemSize * itemAmt_testimonial_slider)
            }, 600);
          } else {
            $(this).closest('.section').find(".testimonial_slider > ul").animate({
              scrollLeft: 0 + $('.testimonial_slider > ul li').outerWidth() * slide
            }, 600);
          }

          /* Change current index to this toggle/event */
          currentIndex_testimonial_slider = slide_num;
          cycleItems();
        });



      // $(window).resize(function(){
      //   if ($(window).width() >= 1024){
      //       // do something here
      //       location.reload();
      //   }
      // });


      $('.collapsible__title').on('click', function(e){
        e.preventDefault();
        var li = $(this).parents(".collapsible__container");
        var answer = $(this).siblings('.collapsible__content');
        var height = answer.children('div').outerHeight();

        // $(this).siblings(".collapsible__expander").toggleClass('active');

        li.toggleClass('open');
        answer.toggleClass('open');
        $(".collapsible__expander").removeClass('active');
        if(answer.hasClass('open')){
          $(this).siblings(".collapsible__expander").addClass('active');
          $('.collapsible__content').removeClass('open').css( "max-height", "" );
          // $('.collapsible__content').css( "padding-top", "15px");
          answer.addClass('open');
          answer.css( "max-height", height+"px" );
        }
        else {
          answer.css( "max-height", "" );
          $(this).siblings(".collapsible__expander").removeClass('active');
        }
      });

      $('.collapsible__expander').on('click', function(e){
        e.preventDefault();
        var li = $(this).parents(".collapsible__container");
        var answer = $(this).siblings('.collapsible__content');
        var height = answer.children('div').outerHeight();

        // $(this).toggleClass('active');

        li.toggleClass('open');
        answer.toggleClass('open');
        $(".collapsible__expander").removeClass('active');

        if(answer.hasClass('open')){

          $(this).addClass('active');
          $('.collapsible__content').removeClass('open').css( "max-height", "" );
          answer.addClass('open');
          answer.css( "max-height", height+"px" );
        }
        else {
          answer.css( "max-height", "" );
          $(this).removeClass('active');
        }
      });





      /* FAQs */
// categories
      $('.section__quick-links .page__link-title li').on('click', function(event){
        event.preventDefault();
        var cat = $(this).attr('data-cat');

        /* Remove class active */
        $('.section__quick-links .page__link-title li').removeClass('active');
        $('.section__quick-links .quick__link-content').removeClass('active');

        /* Add class active to this toggle and the event that it is associated with */
        $(this).addClass('active');
        $('.section__quick-links .quick__link-content[data-cat='+cat+']').addClass('transition');
        setTimeout(function(){
          $('.section__quick-links .quick__link-content[data-cat='+cat+']').addClass('active').removeClass('transition');
        }, 400);
      });

      /* Trigger Scrolling Animation */
      $(window).on('scroll', function () {
        $('.scrolling').each(function() {
          if ( isElementInViewport($(this), 100) ) {
            $(this).removeClass('scrolling');
            set_animation($(this));
          }
        });
      });
      setTimeout(function(){
        $('.scrolling').each(function() {
          if ( isElementInViewport($(this), 100) ) {
            $(this).removeClass('scrolling');
            set_animation($(this));
          }
        });
      },500);


      /* Smooth Scroll */
      $('.scroll-top').click(function(){
        $('html, body').animate({
          scrollTop: $( $(this).attr('href') ).offset().top
        }, 700);
        return false;
      });

      //PARALLAX//

$.fn.moveIt = function(){
        var $window = $(window);
        var instances = [];

        $(this).each(function(){
          instances.push(new moveItItem($(this)));
        });

        window.onscroll = function(){
          var scrollTop = $window.scrollTop();
          instances.forEach(function(inst){
            inst.update(scrollTop);
          });
        };
      };

      var moveItItem = function(el){
        this.el = $(el);
        this.speed = parseInt(this.el.attr('data-scroll-speed'));
      };

      moveItItem.prototype.update = function(scrollTop) {

        if(!isElementInViewport(this.el)){
          return;
        }
        if ($(window).width() > 992 && $(window).height() > 600) {
          var pageY = this.el.offset();
          var pos = (scrollTop - pageY.top) / this.speed;
          this.el.css('transform', 'translateY(' +pos + 'px)');
        }
      };

      $(function(){
        $('[data-scroll-speed]').moveIt();

      });

    // Hamburger Menu
      $(window).on('load', function(){
      $('.btn__toggle').on('click', function(e){
      e.preventDefault();
      /* Set Variables */
      var menu_height = $('.nav-mobile').outerHeight();

      $('.section__header').toggleClass('open');
      $('body, html').toggleClass('mobile-menu-open');
      $('.btn__toggle').toggleClass('open');
      $('.nav__container').toggleClass('open');
    });


    $('.nav-mobile .dropdown-toggle').on('click', function(e){
      e.preventDefault();
      /* Set Variables */
      var menu_height = $('.nav-mobile').outerHeight();
      var dropdown_length = $(this).siblings('.dropdown-menu').children('li').length;
      var li_height = $(this).siblings('.dropdown-menu').children('li').outerHeight();
      var dropdown_height = li_height * dropdown_length;
      var menu_dropdown_height = menu_height + dropdown_height;

          if (!$(this).hasClass('open')) {
            $('.dropdown-toggle, .dropdown-menu').removeClass('open');
            $(this).toggleClass('open');
            $(this).siblings('.dropdown-menu').toggleClass('open');

          } else {
            $('.dropdown-toggle, .dropdown-menu').removeClass('open');
          }

          if($('.nav-mobile .dropdown-menu').hasClass('open')) {
            $('.nav-mobile .dropdown-menu').css('max-height', '');
            $('.nav-mobile .dropdown-menu.open').css('max-height', dropdown_height+'px');
          } else {
            $('.nav-mobile .dropdown-menu').css('max-height', '');
          }
        });
      });
    }
  },

  /*
    Front Page
  */
  home: {
    init: function() {

        // var timer = function(){
        //     autoSlide = setInterval(function() {
        //     items_testimonial_slider.removeClass('active');
        //     toggle_testimonial_slider.removeClass('active');
        //     currentIndex_testimonial_slider += 1;
        //     if (currentIndex_testimonial_slider > itemAmt_testimonial_slider - 1) {
        //       currentIndex_testimonial_slider = 0;
        //     }
        //     cycleItems();
        //   }, 6000);
        // };
        // timer();

      /* Toggles */
      // $('.testimonial_slider__toggle li').on('click', function(event){
      // 	event.preventDefault();
      //   clearInterval(autoSlide);
      //   timer();
      //
      // 	var slide = $(this).attr('data-slide');
      // 	var slide_num = parseInt(slide);
      //   var leftPos = $('.testimonial_slider > ul').scrollLeft(),
      // 		  itemSize = $('.testimonial_slider li').outerWidth(),
      //       ulSize = $('.testimonial_slider > ul').outerWidth(),
      //       ulSize = parseInt(ulSize);
      // 	/* Remove class active */
      // 	$('.testimonial_slider__toggle li').removeClass('active');
      // 	$('.testimonial__wrapper').removeClass('active');
      //
      // 	/* Add class active to this toggle and the event that it is associated with */
      //   $('.testimonial_slider__toggle li[data-slide="'+slide+'"]').addClass('active');
      // 	$('.testimonial_slider li[data-slide="'+slide+'"]').addClass('active');
      //
      //
      // 	if($(this).attr('data-slide') == itemAmt_testimonial_slider - 1) {
      // 		$(".testimonial_slider > ul").animate({
      // 			scrollLeft: 0 + (itemSize * itemAmt_testimonial_slider)
      // 		}, 600);
      // 	} else {
      // 		$(".testimonial_slider > ul").animate({
      // 			scrollLeft: 0 + $('.testimonial_slider > ul li').outerWidth() * slide
      // 		}, 600);
      // 	}
      //
      // 	/* Change current index to this toggle/event */
      // 	currentIndex_testimonial_slider = slide_num;
      //   cycleItems();
      // });
    }
  },
  page: {
    init: function() {

      $('.testimonial_slider__toggle li').on('click', function(event){
      	event.preventDefault();
        clearInterval(autoSlide);
        timer();

      	var slide = $(this).attr('data-slide');
      	var slide_num = parseInt(slide);
        var leftPos = $('.testimonial_slider > ul').scrollLeft(),
      		  itemSize = $('.testimonial_slider li').outerWidth(),
            ulSize = $('.testimonial_slider > ul').outerWidth();
            ulSize = parseInt(ulSize);
      	/* Remove class active */
      	$('.testimonial_slider__toggle li').removeClass('active');
      	$('.testimonial__wrapper').removeClass('active');

      	/* Add class active to this toggle and the event that it is associated with */
        $('.testimonial_slider__toggle li[data-slide="'+slide+'"]').addClass('active');
      	$('.testimonial_slider li[data-slide="'+slide+'"]').addClass('active');


      	if($(this).attr('data-slide') == itemAmt_testimonial_slider - 1) {
      		$(".testimonial_slider > ul").animate({
      			scrollLeft: 0 + (itemSize * itemAmt_testimonial_slider)
      		}, 600);
      	} else {
      		$(".testimonial_slider > ul").animate({
      			scrollLeft: 0 + $('.testimonial_slider > ul li').outerWidth() * slide
      		}, 600);
      	}

      	/* Change current index to this toggle/event */
      	currentIndex_testimonial_slider = slide_num;
        cycleItems();
      });

      timer = function(){
          autoSlide = setInterval(function() {
          items_testimonial_slider.removeClass('active');
          toggle_testimonial_slider.removeClass('active');
          currentIndex_testimonial_slider += 1;
          if (currentIndex_testimonial_slider > itemAmt_testimonial_slider - 1) {
            currentIndex_testimonial_slider = 0;
          }
          cycleItems();
        }, 6000);
      };
      timer();





    }
  },
  page_template_template_insights: {
    init: function() {

      var fn_timer;
      var fn_autoSlide;
      var currentIndex_testimonial_slider = 0,
        toggle_testimonial_slider = $('.featured_slider__toggle li'),
        itemAmt_testimonial_slider = toggle_testimonial_slider.length;

      fn_timer = function(){
          fn_autoSlide = setInterval(function() {
          currentIndex_testimonial_slider += 1;
          if (currentIndex_testimonial_slider > itemAmt_testimonial_slider - 1) {
            currentIndex_testimonial_slider = 0;
          }
          toggle_testimonial_slider.eq(currentIndex_testimonial_slider).trigger('click');
        }, 6000);
      };
      // fn_timer();

      var container = '<div class="content__article-item">' +
          '<span class="article__text">'+
              '<div class="heading__3"><a href="{{ url }}">{{ title }}</a></div>'+
              '<p class="article__blurb">{{ excerpt }}</p>'+
              '<p class="article__read-more"><a href="{{ url }}">{{ language.read_more }} +</a></p>'+
          '</span>'+
          '<a href="{{ url }}" class="article__img" style="background-image: url(\'{{ image }}\')"></a>'+
      '</div>';
      var page = 1;
      var has_more = true;
      var loading_more = false;

      $(document).ready(function () {
          $('.content__article-block').on('scroll', chk_scroll);
      });

      function chk_scroll(e) {
          var elem = $(e.currentTarget);

          if (elem[0].scrollHeight - elem.scrollTop() - 250 <= elem.outerHeight()) {
            if(!loading_more){
              loadMore();
            }
          }
      }

      $('.featured_post__single').on('mouseover', function(){
        clearInterval(fn_autoSlide);
      });

      $('.featured_post__single').on('mouseout', function(){
        fn_timer();
      });

      $('.featured_slider__toggle li').on('click', function(event){
        event.preventDefault();

        var post = $(this).attr('data-post');

        /* Remove class active */
        $('.featured_slider__toggle li').removeClass('active');
        $('.featured_post__single').removeClass('active');

        /* Add class active to this toggle and the event that it is associated with */
        $(this).addClass('active');
        $('.featured_post__single[data-post='+post+']').addClass('transition');
        setTimeout(function(){
          $('.featured_post__single[data-post='+post+']').addClass('active').removeClass('transition');
        }, 400);
      });

      loadMore();

      function loadMore(){
        loading_more = true;
        $.getJSON('/wp-json/wp/v2/posts?_embed&filter[orderby]=date&order=desc&page=' + page,function(data){
          $.each(data, function(index,value){
            var output = container.replace('{{ title }}', value.title.rendered)
            .replace('{{ excerpt }}', value.excerpt.rendered)
            .replace('{{ language.read_more }}', language.read_more)
            .replace('{{ image }}', value.fimg_url)
            .replace(/{{ url }}/g, value.link);

            $(output).insertBefore('.blog--loading');

          });
          page++;

          if(data.length < 10){
            has_more = false;
            $('.blog--loading').hide();
            $('.content__article-block').off('scroll');
          }
          loading_more = false;
        })
        .error(function(data) {
           if(data.responseJSON.code == 'rest_post_invalid_page_number'){
             has_more = false;
             $('.blog--loading').hide();
             $('.content__article-block').off('scroll');
           }
        });
      }




    }
  },

  /*
    Postload
  */
  postload: {
    init: function() {

    }
  },

};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Roots;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('preload');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });

    UTIL.fire('postload');
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery);
var timer;
var currentIndex_testimonial_slider = 0,
  items_testimonial_slider = $('.slider__block .testimonial__wrapper'),
  toggle_testimonial_slider = $('.testimonial_slider__toggle li'),
  itemAmt_testimonial_slider = items_testimonial_slider.length;


function cycleItems() {
  var item = $('.testimonial_slider__toggle li[data-slide="'+currentIndex_testimonial_slider+'"]');
  var toggle = $('.slider__block .testimonial__wrapper[data-slide="'+currentIndex_testimonial_slider+'"]');
  item.addClass('active');
  toggle.addClass('active');
}
var autoSlide;
